// .image-row {
//     display: flex;
//     justify-content: center;
//     max-width: 1224px;
//     //justify-content: space-between;
//     align-items: center;
//     margin: 1rem 0;
//   }
.image-row {
    display: flex;
    justify-content: center;
    max-width: 1224px;
    //justify-content: space-between;
    align-items: center;
    margin: 3rem auto;
}
  
  .image-container {
    width: 95%;
    margin: 0 0.3rem;
  }
  
//   .image-container img {
//     max-width: 100%;
//     height: auto;
//   }

  .image-container img {
    // max-width: 366px; /* Set the maximum width */
    // max-height: 421px; /* Set the maximum height */
    max-width: 100%;
    height: auto;
    width: auto;
    object-fit: contain;
    border-radius: 15px;
  }

  .image-row .image-container img:hover {
    cursor: pointer;
  }