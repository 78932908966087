// #App-content {
//     padding-bottom: 30px;
//   }
.summerflavorguide {
  .recipe-content-page {
      .recipeArea {
          h2.sectionTitle {
              display: none;
          }
          .recipe-content {
              .recipe-section {
                  h3.sectionTitle {
                      text-align: left;
                      p {
                          font-size: 1rem;
                          font-weight: normal; 
                      }
                  }
              }
              #Recipes{
                  margin-top: 2em;
              }
          }
      }
  }
}